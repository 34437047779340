import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'


const StyledBackground = styled.div`
  background-image: url(${({theme}) => theme.texture?.src});
  background-position: center top;
  background-size: contain;
  ${({theme}) => !theme.texture?.repeat && css`
    background-repeat: no-repeat;
  `}
`

const FullPageBackground = ({children}) => {
  return (
    <StyledBackground>
      {children}
    </StyledBackground>
  )
}

FullPageBackground.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullPageBackground
