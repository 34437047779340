import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../../uikit/src/utils/media'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: ${({theme}) => theme.border.radius.lg};
  background-color: ${({theme}) => theme.colors.background2};
  padding: 2.5rem 3rem 2.5rem 4rem;
  max-width: 53rem;
  ${media.down('mobile')(css`
    width: 100%;
    padding: 2.375rem 2.125rem 1.375rem 1.5rem;
  `)}

  h4 {
    text-align: center;
  }

  p {
    padding-top: ${({theme}) => `calc(${theme.spacer} * 3)`};
  }
`

const ContentContainer = ({children, ...props}) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node,
}

export default ContentContainer
