import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/car.svg'


const CarIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

CarIcon.defaultProps = {
  ...defaultProps,
}

CarIcon.propTypes = {
  ...propTypes,
}

export default CarIcon
