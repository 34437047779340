import {Suspense} from 'react'
import PropTypes from 'prop-types'
import useIsClient from '../hooks/useIsClient'


const SSRSuspense = ({fallback, ...props}) => {
  const isClient = useIsClient()
  if (!isClient) return fallback

  return <Suspense fallback={fallback} {...props} />
}
SSRSuspense.propTypes = {
  // Suspense requires fallback
  fallback: PropTypes.node.isRequired,
}

export default SSRSuspense
