import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/scaling.svg'


const ScalingIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

ScalingIcon.defaultProps = {
  ...defaultProps,
}

ScalingIcon.propTypes = {
  ...propTypes,
}

export default ScalingIcon
