import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledCardsContainer = styled.div`
  div:nth-child(even) {
    & > :first-child {
      order: 1;
    }
  }
`

const CardsContainer = ({children}) => {
  return (
    <StyledCardsContainer>
      {children}
    </StyledCardsContainer>
  )
}

CardsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardsContainer
