import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/ski.svg'


const SkiIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

SkiIcon.defaultProps = {
  ...defaultProps,
}

SkiIcon.propTypes = {
  ...propTypes,
}

export default SkiIcon
