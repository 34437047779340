import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash'
import {If} from 'babel-plugin-jsx-control-statements'
import * as typography from '../theme/typography'
import omitProps from '../utils/omitProps'
import Button from './Button'


const StyledCard = styled('div').withConfig(omitProps(['active']))`
  display: flex;
  flex-direction: column;
  border: 3px solid transparent;
  border-radius: ${({theme}) => theme.border.radius.xl};
  isolation: isolate;
  overflow: hidden;
  ${({active, theme}) => active && css`
    border-color: ${theme.colors.primary};
  `}
  ${({onClick}) => onClick && css`
    cursor: pointer;
  `}
`

const StyledImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 11.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledLabel = styled(Button)`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: default;

  span {
    text-transform: none;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({theme}) => theme.colors.background2};
  padding: 2.5rem 3rem;
  width: 100%;

  ul {
    margin-bottom: 2rem;
    padding-left: 1rem;
    list-style: none;

    li {
      line-height: 2rem;

      ::before {
        display: inline-block;
        margin-left: -1em;
        width: 1em;
        color: ${({theme}) => theme.colors.primary};
        font-weight: bold;
        content: "•";
      }
    }
  }
`

const StyledTitle = styled.span`
  margin-bottom: 1.5rem;
  text-align: center;
  ${typography.h4}
`

const StyledPrice = styled.span`
  text-align: center;
  color: ${({theme}) => theme.colors.primary};
  ${typography.h4}
`

const PackageCard = ({title, image, component, highlightedText, price, active, onClick, children, ...props}) => {
  return (
    <StyledCard active={active} onClick={onClick} as={component} {...props}>
      <StyledImgContainer>
        {image}
        <If condition={!isEmpty(highlightedText)}>
          <StyledLabel component="span">{highlightedText}</StyledLabel>
        </If>
      </StyledImgContainer>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        {children}
        <StyledPrice>{price}</StyledPrice>
      </StyledContent>
    </StyledCard>
  )
}

PackageCard.propTypes = {
  component: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  highlightedText: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.object,
}

export default PackageCard
