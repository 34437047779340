import styled from 'styled-components'


const StyledLoader = styled.span`
  box-sizing: border-box;
  display: inline-block;
  margin: 1rem auto;
  border: 5px solid ${({theme}) => theme.colors.primary};
  border-radius: 50%;
  border-bottom-color: transparent;
  width: 48px;
  height: 48px;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = () => {
  return (
    <StyledLoader />
  )
}

export default Loader
