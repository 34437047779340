import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../../uikit/src/utils/media'


const StyledSection = styled.div`
  padding: 3.25rem 0;
  ${media.down('mobile')(css`
    padding: 1.75rem 0;
  `)}
`

const Section = ({children}) => {
  return (
    <StyledSection>
      {children}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
