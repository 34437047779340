import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import * as typography from '../theme/typography'


const applyVariant = ({variant}) => {
  if (variant === 'plain') {
    return css`
      background: transparent;
      padding: 0;
    `
  } else {
    return css`
      border-radius: ${({theme}) => theme.border.radius.xl};
      background-color: ${({theme}) => theme.colors.primary};
      padding: 0.75em 1.75em;
      width: fit-content;
    `
  }
}

const StyledButton = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  ${applyVariant}
  ${({color}) => color && css`
    background-color: ${({theme}) => theme.colors[color]};
  `}
  ${({disabled}) => disabled && css`
    opacity: 0.75;
    cursor: not-allowed;
  `}
`

const StyledSpan = styled.span`
  text-align: center;
  color: ${({theme}) => theme.colors.text2};
  ${({variant}) => variant !== 'plain' && css`
    ${typography.button}
  `}
`

const Button = ({onClick, href, to, component, variant, color, children, ...props}) => {
  return (
    <StyledButton
        type={onClick ? 'button' : null}
        href={href}
        to={to}
        as={component}
        variant={variant}
        onClick={onClick}
        color={color}
        {...props}
    >
      <StyledSpan variant={variant}>{children}</StyledSpan>
    </StyledButton>
  )
}

Button.defaultProps = {
  variant: 'filled',
}

Button.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  component: PropTypes.elementType,
  variant: PropTypes.oneOf(['plain', 'filled']),
  color: PropTypes.string,
  children: PropTypes.node,
}

export default Button
