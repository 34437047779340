import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/mind-shape.svg'


const MindShapeIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

MindShapeIcon.defaultProps = {
  ...defaultProps,
}

MindShapeIcon.propTypes = {
  ...propTypes,
}

export default MindShapeIcon
