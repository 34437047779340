import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import * as typography from '../../../uikit/src/theme/typography'
import media from '../../../uikit/src/utils/media'


const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 1fr;
  gap: 1rem;
  align-items: center;
  border-radius: ${({theme}) => theme.border.radius.lg};
  background-color: ${({theme}) => theme.colors.background2};
  padding: 1.75rem;
  width: 100%;
  ${media.down('mobile')(css`
    padding: 1.5;
  `)}
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled.span`
  ${typography.h4}
  color: ${({theme}) => theme.colors.text3};
`

const StyledIconWrapper = styled.div`
  display: grid;
  place-items: center;
`

const InfoCard = ({icon, title, description}) => {
  return (
    <StyledContainer>
      <StyledIconWrapper>
        {icon}
      </StyledIconWrapper>
      <StyledContentWrapper>
        <StyledTitle>{title}</StyledTitle>
        <p>{description}</p>
      </StyledContentWrapper>
    </StyledContainer>
  )
}

InfoCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default InfoCard
