import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/performance.svg'


const PerformanceIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

PerformanceIcon.defaultProps = {
  ...defaultProps,
}

PerformanceIcon.propTypes = {
  ...propTypes,
}

export default PerformanceIcon
