import {lazy} from 'react'


const waitForLoad = () => (new Promise((resolve) => {
  if (typeof window === 'undefined') return

  const handlePageLoad = (e) => {
    if (e) window.removeEventListener('load', handlePageLoad)
    if ('requestIdleCallback' in window) {
      const callback = (deadline) => {
        if (!deadline.timeRemaining() > 1000) return window.requestIdleCallback(callback)
        resolve()
      }
      window.requestIdleCallback(callback)
    } else {
      resolve()
    }
  }

  if (document.readyState === 'complete') {
    handlePageLoad()
  } else {
    window.addEventListener('load', handlePageLoad)
  }
}))

export const lazyAfterLoad = (component) => lazy(async () => {
  await waitForLoad()
  return component()
})
