import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import * as typography from '../theme/typography'


const StyledCard = styled.div`
  display: flex;
  margin: 3.75rem 0;
  border-radius: ${({theme}) => theme.border.radius.xl};
  isolation: isolate;
  background-color: ${({theme}) => theme.colors.background2};
  overflow: hidden;
  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 3rem;
  width: 50%;
  ${media.down('mobile')(css`
    width: 100%;
    order: 1;

    button {
      text-align: center;
    }
  `)}

  /* stylelint-disable */
  ${({inverted}) => inverted
    ? css`
      order: 1;
    ` : css`
      order: 0;
  `}
`

const StyledTitle = styled.div`
  margin-bottom: 1.5rem;
`

const StyledDescription = styled.div`
  margin-bottom: 1.5rem;
`

const StyledSpan = styled.span`
  ${typography.body}
  color: ${({theme}) => theme.colors.aux};
`

const StyledImgContainer = styled.div`
  width: 50%;
  ${media.down('mobile')(css`
    width: 100%;
  `)}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 30%;
  }
`

const Card = ({inverted, title, description, image, children}) => {
  return (
    <StyledCard>
      <StyledContent inverted={inverted}>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledSpan>{children}</StyledSpan>
      </StyledContent>
      <StyledImgContainer>
        {image}
      </StyledImgContainer>
    </StyledCard>
  )
}

Card.propTypes = {
  inverted: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  image: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default Card
