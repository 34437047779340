import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/scale-up.svg'


const ScaleUpIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

ScaleUpIcon.defaultProps = {
  ...defaultProps,
}

ScaleUpIcon.propTypes = {
  ...propTypes,
}

export default ScaleUpIcon
