import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../utils/media'
import Text from './Text'


const StyledConsentContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding: 0 0 0.75rem 1.5rem;
  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-top: 0.75rem;
    line-height: 0;
  }
`

const StyledHint = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-self: end;
  ${media.down('mobile')(css`
    position: unset;
    margin-top: 0.75rem;
  `)}
`

const ConsentSection = ({hint, children, ...props}) => {
  return (
    <StyledConsentContainer {...props}>
      <StyledCheckboxContainer>
        {children}
      </StyledCheckboxContainer>
      <If condition={hint}>
        <StyledHint>
          <Text component="span" color="negative">*&nbsp;</Text>
          <Text component="span" color="textInverted">{hint}</Text>
        </StyledHint>
      </If>
    </StyledConsentContainer>
  )
}

ConsentSection.propTypes = {
  hint: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ConsentSection
