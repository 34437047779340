import {fonts, heroTexture} from 'uikit'
import {transparentize} from 'polished'
import texture from './assets/texture.png'


const ORANGE = '#F03A00'
const BLACK = '#050505'
const WHITE = '#FFFFFF'
const GRAY = '#BEBEBE'
const DARK_GRAY = '#414141'
const LIGHT_GRAY = '#F5F5F5'
const RED = '#FF0000'

const theme = {
  colors: {
    primary: ORANGE,
    secondary: DARK_GRAY,
    text: DARK_GRAY,
    text2: WHITE,
    text3: BLACK,
    background: LIGHT_GRAY,
    background2: WHITE,
    background3: DARK_GRAY,
    background4: DARK_GRAY,
    aux: GRAY,
    negative: RED,
  },
  texture: {
    src: texture,
    repeat: true,
  },
  heroTexture,
  fonts: {
    family: {
      primary: fonts.supremeRegular.name,
      secondary: fonts.supremeExtraBold.name,
    },
  },
  overlays: {
    background: transparentize(0.5, BLACK),
    backgroundNoBlur: transparentize(0.3, BLACK),
    backgroundNoBlurCookieBar: transparentize(0.125, BLACK),
  },
  border: {
    default: '1px solid',
    radius: {
      xs: '0.25rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '1.25rem',
      xl: '1.75rem',
    },
  },
}

export default theme
