import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import {createGlobalStyle} from 'styled-components'


const CustomFont = createGlobalStyle`
  @font-face {
    font-family: ${({name}) => name};
    src: url(${({font}) => font});
    font-weight: ${({theme, weight}) => theme.fonts.weight[weight]};
    font-display: swap;
  }
`

const LocalFonts = ({fonts}) => {
  return (
    <>
      <For each="font" of={fonts}>
        <CustomFont key={font.name} name={font.name} font={font.font} weight={font.weight || 'regular'} />
      </For>
    </>
  )
}

LocalFonts.propTypes = {
  fonts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    font: PropTypes.string.isRequired,
    weight: PropTypes.string,
  })).isRequired,
}

export default LocalFonts
