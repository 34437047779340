import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'


const StyledFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({theme}) => `calc(${theme.spacer} * 2.5) calc(${theme.spacer} * 2)`};
  margin: 0 auto;
  margin-bottom: ${({theme}) => `calc(${theme.spacer} * 2.5)`};
  border-radius: ${({theme}) => theme.border.radius.md};
  padding: ${({theme}) => theme.spacer} 0;
  max-width: 53rem;
  ${media.down('mobile')(css`
    grid-template-columns: 1fr;
    padding: 2rem 0 0.5rem;
    width: 100%;
  `)}
`

const FormContainer = ({children}) => {
  return (
    <StyledFormContainer>
      {children}
    </StyledFormContainer>
  )
}

FormContainer.propTypes = {
  children: PropTypes.node,
}

export default FormContainer
