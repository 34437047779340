import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/bike3.svg'


const Bike3Icon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

Bike3Icon.defaultProps = {
  ...defaultProps,
}

Bike3Icon.propTypes = {
  ...propTypes,
}

export default Bike3Icon
