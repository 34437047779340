const BLACK = '#000000'
const WHITE = '#FFFFFF'

// Fonts
const FONT_SIZE_BASE = 16
const FONT_SIZE_MOBILE = 14

export const px2rem = (size, base = FONT_SIZE_BASE) => `${size / base}rem`


const theme = {
  spacer: '0.5rem',
  colors: {
    primary: BLACK,
    secondary: BLACK,
    text: BLACK,
    text2: BLACK,
    text3: BLACK,
    background: WHITE,
    background2: WHITE,
    aux: BLACK,
  },
  fonts: {
    family: {
      primary: 'Arial',
    },
    size: {
      xs: '0.75rem',
      sm: '1rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '2rem',
      xxl: '3rem',
    },
    weight: {
      regular: '400',
      semiBold: '600',
      bold: '700',
      extraBold: '800',
    },
    lineHeight: {
      xs: '1.5',
      sm: '1.5',
      md: '1.5',
      lg: '1.5',
      xl: '1.5',
      xxl: '1.5',
    },
    fontStyle: {
      normal: 'normal',
      italic: 'italic',
    },
  },
  responsive: {
    breakpoints: {
      mobile: 768,
      desktop: 1440,
    },
    zoom: {
      minimalViewport: 320,
      maximalViewport: 1440,
      designedViewport: 1920,
      fontSizeBase: FONT_SIZE_BASE,
      fontSizeMobile: FONT_SIZE_MOBILE,
    },
    container: {
      desktop: px2rem(1550),
      mobile: px2rem(720, FONT_SIZE_MOBILE),
    },
  },
  animation: '.2s ease-in',
  border: {
    default: '1px solid',
    radius: {
      xxs: '0.5rem',
      xs: '0.5rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '0.5rem',
      xl: '0.5rem',
    },
  },
}

export default theme
