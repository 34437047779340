import supremeRegularFont from '../assets/fonts/superemeRegular.woff2'
import supremeExtraBoldFont from '../assets/fonts/supremeExtraBold.otf'
import satoshiRegularFont from '../assets/fonts/satoshiRegular.woff2'
import satoshiBoldFont from '../assets/fonts/satoshiBold.woff2'
import stratosBoldFont from '../assets/fonts/stratosBold.ttf'
import stratosRegularFont from '../assets/fonts/stratosRegular.ttf'


export const supremeRegular = {
  name: 'Supreme Regular',
  font: supremeRegularFont,
}

export const supremeExtraBold = {
  name: 'Supreme ExtraBold',
  font: supremeExtraBoldFont,
}

export const satoshiRegular = {
  name: 'Satoshi Regular',
  font: satoshiRegularFont,
}

export const satoshiBold = {
  name: 'Satoshi Bold',
  font: satoshiBoldFont,
}

export const stratosRegular = {
  name: 'Stratos Regular',
  font: stratosRegularFont,
}

export const stratosBold = {
  name: 'Stratos Bold',
  font: stratosBoldFont,
}

export const webFonts = [
  supremeRegular,
  supremeExtraBold,
  satoshiRegular,
  satoshiBold,
  stratosRegular,
  stratosBold,
]
