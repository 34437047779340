import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import * as typography from '../theme/typography'


const StyledCard = styled.div`
  display: flex;
  margin: 3.75rem 0;
  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const StyledImgContainer = styled.div`
  margin: -2rem 0;
  border-radius: ${({theme}) => theme.border.radius.xl};
  isolation: isolate;
  width: 50%;
  height: auto;
  overflow: hidden;
  ${media.down('mobile')(css`
    border-radius: ${({theme}) => theme.border.radius.xl} ${({theme}) => theme.border.radius.xl} 0 0;
    margin: unset;
    width: 100%;
    height: 15rem;
  `)}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  border-top-right-radius: ${({theme}) => theme.border.radius.xl};
  border-bottom-right-radius: ${({theme}) => theme.border.radius.xl};
  background-color: ${({theme}) => theme.colors.background2};
  padding: 2.5rem 3rem;
  width: 50%;
  ${({theme}) => media.down('mobile')(css`
    align-items: center;
    border-radius: 0 0 ${theme.border.radius.xl} ${theme.border.radius.xl};
    width: 100%;
  `)}
`

const StyledTitle = styled.div`
  margin-bottom: 1.5rem;
`

const StyledDescription = styled.span`
  margin-bottom: 1.5rem;
`

const StyledSpan = styled.span`
  ${typography.body}
  color: ${({theme}) => theme.colors.aux};
`

const HighlightedImageCard = ({title, description, image, children}) => {
  return (
    <StyledCard>
      <StyledImgContainer>
        {image}
      </StyledImgContainer>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledSpan>{children}</StyledSpan>
      </StyledContent>
    </StyledCard>
  )
}

HighlightedImageCard.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  image: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default HighlightedImageCard
