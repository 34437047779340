import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/checkbox.svg'


const CheckboxIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

CheckboxIcon.defaultProps = {
  ...defaultProps,
}

CheckboxIcon.propTypes = {
  ...propTypes,
}

export default CheckboxIcon
