import {css} from 'styled-components'


export const body = css`
  line-height: ${({theme}) => theme.fonts.lineHeight.sm};
  font-size: ${({theme}) => theme.fonts.size.sm};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
`

export const h1 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.xxl};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.xxl};
`

export const h2 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.xxl};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.xxl};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
`

export const h2Outlined = css`
  ${h2}
  color: transparent;
  -webkit-text-stroke: 1px ${({theme}) => theme.colors.text};
  /* stylelint-disable-next-line property-no-unknown */
  text-stroke: 1px ${({theme}) => theme.colors.text};
`

export const h3 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.xl};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.xl};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h4 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.lg};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.lg};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h5 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.md};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.md};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h6 = css`
  text-transform: uppercase;
  line-height: ${({theme}) => theme.fonts.lineHeight.sm};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.sm};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const small = css`
  line-height: ${({theme}) => theme.fonts.lineHeight.xs};
  font-size: ${({theme}) => theme.fonts.size.xs};
`

export const button = css`
  line-height: ${({theme}) => theme.fonts.lineHeight.md};
  font-family: ${({theme}) => theme.fonts.family.secondary};
  font-size: ${({theme}) => theme.fonts.size.md};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export default css`
  body {
    ${body}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  h6 {
    ${h6}
  }

  small {
    ${small}
  }

  input,
  label {
    ${body}
  }

  strong {
    font-weight: ${({theme}) => theme.fonts.weight.extraBold};
  }

  i {
    font-style: ${({theme}) => theme.fonts.fontStyle.italic};
  }

  .sr-only {
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }
`
