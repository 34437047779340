import sk from './src/assets/sk.png'
import en from './src/assets/en.png'
import hu from './src/assets/hu.png'
import cs from './src/assets/cs.png'
import * as fonts from './src/theme/fonts'


const flags = {
  sk,
  en,
  hu,
  cs,
}

export {
  fonts,
  flags,
}

export * from './src/icons'
export {default as Accordion} from './src/components/Accordion'
export {default as AccordionContainer} from './src/components/AccordionContainer'
export {default as Alert} from './src/components/Alert'
export {default as Button} from './src/components/Button'
export {default as ButtonCard} from './src/components/ButtonCard'
export {default as Card} from './src/components/Card'
export {default as CardsContainer} from './src/components/CardsContainer'
export {default as Checkbox} from './src/components/form/Checkbox'
export {default as ConsentSection} from './src/components/ConsentSection'
export {default as ContentContainer} from './src/components/ContentContainer'
export {default as CookieBar} from './src/components/CookieBar'
export {default as Flex} from './src/components/Flex'
export {default as Footer} from './src/components/Footer'
export {default as FormContainer} from './src/components/form/FormContainer'
export {default as FullPageBackground} from './src/components/FullPageBackground'
export {default as HelperText} from './src/components/form/HelperText'
export {default as Hero} from './src/components/Hero'
export {default as heroTexture} from './src/assets/hero-texture.png'
export {default as HighlightedImageCard} from './src/components/HighlightedImageCard'
export {default as Icon} from './src/components/Icon'
export {default as Iframe} from './src/components/Iframe'
export {default as InfoCard} from './src/components/InfoCard'
export {default as LangSwitcher} from './src/components/LangSwitcher'
export {default as Loader} from './src/components/Loader'
export {default as LocalFonts} from './src/theme/LocalFonts'
export {default as Main} from './src/components/Main'
export {default as Navbar} from './src/components/Navbar'
export {default as PackageCard} from './src/components/PackageCard'
export {default as Paper} from './src/components/Paper'
export {default as PaymentPageWrapper} from './src/components/PaymentPageWrapper'
export {default as Section} from './src/components/Section'
export {default as Slider} from './src/components/Slider/lazy'
export {default as SSRSuspense} from './src/components/SSRSuspense'
export {default as Text} from './src/components/Text'
export {default as TextInput} from './src/components/form/TextInput'
export {default as ThemeProvider} from './src/theme/ThemeProvider'

// Hooks
export {default as useIsClient} from './src/hooks/useIsClient'
