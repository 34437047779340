import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/map.svg'


const MapIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

MapIcon.defaultProps = {
  ...defaultProps,
}

MapIcon.propTypes = {
  ...propTypes,
}

export default MapIcon
