import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../../uikit/src/utils/media'


const StyledWrapper = styled.div`
  padding: 5rem 0;
  min-height: calc(100vh - 10.5rem);
  ${media.down('mobile')(css`
    padding: 7rem 0;
  `)}
`

const PaymentPageWrapper = ({children}) => {
  return (
    <StyledWrapper>
      {children}
    </StyledWrapper>
  )
}

PaymentPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaymentPageWrapper
