import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import {merge} from 'lodash-es'
import GlobalStyles from './GlobalStyles'
import defaultTheme from './theme'


const ThemeProvider = ({theme, children, ...props}) => {
  return (
    <StyledThemeProvider theme={merge({}, defaultTheme, theme)} {...props}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.defaultProps = {
  theme: {},
}

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
