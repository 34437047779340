import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {without} from 'lodash-es'


const StyledBackground = styled.div`
  background-image: url(${({theme}) => theme.texture?.src && theme.texture.src});
  background-position: center 15%;
  ${({theme}) => !theme.texture?.repeat && css`
    background-repeat: no-repeat;
  `}
  ${({theme}) => !theme.texture?.cover && css`
    background-size: contain;
  `}
`

const Main = ({children}) => {
  const otherComponents = without(children, children[0])
  return (
    <main>
      {children[0]}
      <StyledBackground>
        <For each="component" of={otherComponents}>
          {component}
        </For>
      </StyledBackground>
    </main>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
