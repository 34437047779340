import PropTypes from 'prop-types'
import {Helmet} from 'gatsby-plugin-react-i18next'
import {If} from 'babel-plugin-jsx-control-statements'
import {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import useStoredState from './useStoredState'


const STORAGE_KEY = 'consent_preferences'
export const INITIAL_CONSENT = {analyticalCookies: true, marketingCookies: true}

const AnalyticsContext = createContext()

export const useConsent = () => {
  return useContext(AnalyticsContext)
}

export const sendPageView = (location, gaKey) => {
  if (!gaKey || !window?.gtag) return
  window.gtag('set', 'page_path', location.pathname)
  window.gtag('event', 'page_view')
}

const updateGoogleConsent = (gaKey, analytics = false, ads = false) => {
  if (!gaKey || !window?.gtag) return
  window.gtag('consent', 'update', {
    analytics_storage: analytics ? 'granted' : 'denied', // eslint-disable-line camelcase
    ad_storage: ads ? 'granted' : 'denied', // eslint-disable-line camelcase
  })
}

const updateFbPixelConsent = (metaPixelId, marketing = false) => {
  if (!metaPixelId || !window?.fbq) return
  window.fbq('consent', marketing ? 'grant' : 'revoke')
}

const AnalyticsProvider = ({children, gaKey, metaPixelId}) => {
  const [preferences, setPreferences] = useStoredState(null, STORAGE_KEY)

  const consent = useCallback((preferences) => {
    updateGoogleConsent(gaKey, preferences.analyticalCookies)
    updateFbPixelConsent(metaPixelId, preferences.marketingCookies)
    setPreferences(preferences)
  }, [gaKey, metaPixelId, setPreferences])

  useEffect(() => {
    if (preferences) {
      consent(preferences)
    }
  }, [preferences, consent])

  const value = useMemo(() => ({
    preferences,
    consent,
  }), [preferences, consent])

  return (
    <>
      <Helmet>
        <If condition={gaKey}>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaKey}`} />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'analytics_storage': 'denied',
              'ad_storage': 'denied',
            });
            gtag('js', new Date());
            gtag('config', '${gaKey}');
            `}
          </script>
        </If>
        <If condition={metaPixelId}>
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('consent', 'revoke');
              fbq('init', '${metaPixelId}');
              fbq('track', 'PageView');
            `}
          </script>
        </If>
      </Helmet>
      <AnalyticsContext.Provider value={value}>
        {children}
      </AnalyticsContext.Provider>
    </>
  )
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node,
  gaKey: PropTypes.string,
  metaPixelId: PropTypes.string,
}

export default AnalyticsProvider
