import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import theme from '../theme/theme'
import * as typography from '../theme/typography'


// Explicitly list for webpack
export const styles = {
  body: typography.body,
  h1: typography.h1,
  h2: typography.h2,
  h2Outlined: typography.h2Outlined,
  h3: typography.h3,
  h4: typography.h4,
  h5: typography.h5,
  h6: typography.h6,
  small: typography.small,
}

const StyledSpan = styled.span`
  ${({icon}) => icon && css`
    display: flex;
    align-items: center;
  `}
  text-align: ${({align}) => align || 'inherit'};
  ${({noWrap}) => noWrap && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${({textStyle}) => textStyle && styles[textStyle]}
  ${({theme, color}) => color && css`
    color: ${theme.colors[color]};
    fill: ${theme.colors[color]};
  `}
`

const Text = ({component, textStyle, color, align, noWrap, icon, children, ...props}) => {
  return (
    <StyledSpan
        as={component}
        textStyle={textStyle}
        color={color}
        align={align}
        noWrap={noWrap}
        icon={icon}
        {...props}
    >
      {children}
    </StyledSpan>
  )
}

Text.defaultProps = {
  component: 'span',
  textStyle: 'body',
}

Text.propTypes = {
  color: PropTypes.oneOf(keys(theme.colors)),
  textStyle: PropTypes.oneOf(keys(styles)),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  noWrap: PropTypes.bool,
  icon: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
}


export default Text
