import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContentContainer from './ContentContainer'


const StyledNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 2.5rem 0;
  width: 100%;
`

const StyledNavWrapper = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: center;
  width: 100%;
`


const Navbar = ({children}) => {
  return (
    <StyledNav>
      <ContentContainer>
        <StyledNavWrapper>
          {children}
        </StyledNavWrapper>
      </ContentContainer>
    </StyledNav>
  )
}

Navbar.propTypes = {
  children: PropTypes.node,
}

export default Navbar
