import PropTypes from 'prop-types'
import {Children} from 'react'
import styled, {css} from 'styled-components'
import omitProps from '../utils/omitProps'


const StyledSwitcherWrapper = styled.div`
  display: inline-block;
  margin-left: auto;
  max-height: 2rem;
`

const StyledSwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: max-height 200ms ease-in-out;
  z-index: 1000;
  border: 1px solid ${({theme}) => theme.colors.text2};
  border-radius: ${({theme}) => theme.border.radius.xs};
  padding: 0.35rem 0.4rem;
  max-height: 2rem;
  ${({disableHover}) => disableHover && css`
    pointer-events: none;
  `}

  &:hover,
  &:focus {
    max-height: 15rem;
  }

  & a:not(:last-child) {
    padding-bottom: 0.4rem;
  }
`

const StyledFlag = styled('div').withConfig(omitProps(['isActive']))`
  position: relative;
  transition: opacity 200ms ease-in-out;
  opacity: ${({isActive}) => isActive ? '1' : '0'};
  width: 1.75rem;
  height: 1.25rem;
  z-index: 0;
  text-align: center;

  img {
    width: 1.75rem;
  }

  /* stylelint-disable */
  ${StyledSwitcherContainer}:hover &,
  /* stylelint-disable */
  ${StyledSwitcherContainer}:focus & {
    opacity: 1;
  }
`

const Flag = ({isActive, src, alt, ...props}) => {
  return (
    <StyledFlag isActive={isActive} {...props}>
      <img src={src} alt={alt} />
    </StyledFlag>
  )
}

Flag.propTypes = {
  isActive: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
}

const LangSwitcher = ({children}) => {
  const childrenLength = Children.toArray(children).length

  return (
    <StyledSwitcherWrapper>
      <StyledSwitcherContainer disableHover={childrenLength < 2}>
        {children}
      </StyledSwitcherContainer>
    </StyledSwitcherWrapper>
  )
}

LangSwitcher.propTypes = {
  children: PropTypes.node,
}

LangSwitcher.Flag = Flag

export default LangSwitcher
