import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/facebook.svg'


const FacebookIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

FacebookIcon.defaultProps = {
  ...defaultProps,
}

FacebookIcon.propTypes = {
  ...propTypes,
}

export default FacebookIcon
