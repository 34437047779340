import PropTypes from 'prop-types'
import {useState} from 'react'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash'
import * as typography from '../../../uikit/src/theme/typography'
import {ChevronIcon} from '../icons'
import media from '../utils/media'
import omitProps from '../utils/omitProps'


const AccordionContainer = styled.details`
  display: flex;
  border-radius: ${({theme}) => theme.border.radius.xl};
  background-color: ${({theme}) => theme.colors.background2};
  cursor: pointer;
  padding: 1.5rem 2rem;
  width: 100%;
  height: min-content;
`

const AccordionSummary = styled.summary`
  display: flex;
  position: relative;
  justify-content: space-between;
  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
  `)}
`

const AccordionOrder = styled.span`
  ${typography.h2}
  align-self: center;
  min-width: 4rem;
  text-align: center;
  line-height: normal;
  color: transparent;
  -webkit-text-stroke: 1px ${({theme}) => theme.colors.primary};
  /* stylelint-disable-next-line property-no-unknown */
  text-stroke: 1px ${({theme}) => theme.colors.primary};
`

const AccordionTitle = styled.div`
  margin-bottom: 0.5rem;
`

const AccordionDescription = styled.div`
  margin-bottom: 0.5rem;
`

const AccordionText = styled.span`
  padding: ${({noPadding}) => noPadding ? '0.5rem  0' : '0.5rem 1rem 0 5.5rem'};
  ${media.down('mobile')(css`
    padding: 1rem 1rem 0;
    text-align: center;
  `)}
`

const AccordionContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ${({noPadding}) => noPadding ? '0' : '1rem 1.5rem'};
  ${media.down('mobile')(css`
    text-align: center;
  `)}
`

const StyledChevronContainer = styled('div').withConfig(omitProps(['rotate']))`
  display: flex;
  align-items: center;
  transform: rotate(${({rotate}) => rotate ? '0' : '180deg'});
`

const Accordion = ({order, title, description, children}) => {
  const [open, setOpen] = useState(false)
  const onClick = (e) => {
    e.preventDefault()
    setOpen(!open)
  }
  return (
    <AccordionContainer smallPadding={isEmpty(order)} onClick={onClick} open={open}>
      <AccordionSummary>
        <If condition={order}>
          <AccordionOrder>{order}</AccordionOrder>
        </If>
        <AccordionContent noPadding={isEmpty(order)}>
          <AccordionTitle>{title}</AccordionTitle>
          <If condition={description}>
            <AccordionDescription>{description}</AccordionDescription>
          </If>
        </AccordionContent>
        <StyledChevronContainer rotate={!open}>
          <ChevronIcon size="sm" noFill />
        </StyledChevronContainer>
      </AccordionSummary>
      <AccordionText noPadding={isEmpty(order)}>
        {children}
      </AccordionText>
    </AccordionContainer>
  )
}

Accordion.propTypes = {
  order: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.string,
  children: PropTypes.string,
}

export default Accordion
