import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import omitProps from '../utils/omitProps'
import theme from '../theme/theme'


const StyledSpan = styled('span').withConfig(omitProps(['noFill']))`
  display: inline-block;
  width: auto;
  height: ${({theme, size}) => size ? theme.fonts.size[size] : 'unset'};
  path,
  g {
    fill: ${({theme}) => theme.colors.primary};
  }

  ${({noFill}) => !noFill && css`
    path,
    g {
      fill: inherit;
    }
  `}

  ${({color}) => color && css`
    path,
    g {
      fill: ${({theme}) => theme.colors[color]};
    }
  `}
`

const Icon = ({icon, color, noFill, ...props}) => {
  return (
    <StyledSpan as={icon} color={color} noFill={noFill} {...props} />
  )
}

export const sizeKeys = keys(theme.fonts.size)

export const propTypes = {
  noFill: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(keys(theme.fonts.size)),
}

export const defaultProps = {
  noFill: false,
}

Icon.defaultProps = {
  ...defaultProps,
}

Icon.propTypes = {
  icon: PropTypes.func.isRequired,
  ...propTypes,
}

export default Icon
