import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/question.svg'


const QuestionIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

QuestionIcon.defaultProps = {
  ...defaultProps,
}

QuestionIcon.propTypes = {
  ...propTypes,
}

export default QuestionIcon
