import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/scale.svg'


const ScaleIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

ScaleIcon.defaultProps = {
  ...defaultProps,
}

ScaleIcon.propTypes = {
  ...propTypes,
}

export default ScaleIcon
