import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../utils/media'


const ButtonCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({theme}) => theme.border.radius.xl};
  background-color: ${({theme}) => theme.colors.background3};
  padding: 4.15rem 0;
  width: 100%;
  height: max-content;
  ${media.down('mobile')(css`
    flex-direction: column;
    padding: 1.5rem 2rem;
  `)}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  ${media.down('mobile')(css`
    width: 100%;
    order: 1;
  `)}
`

const ContentContainer = styled.div`
  justify-content: center;
  padding: 1rem;
  width: 50%;
  color: ${({theme}) => theme.colors.text2};
  ${media.down('mobile')(css`
    padding: 1rem;
    width: 100%;
  `)}
`

const ButtonCard = ({button, children}) => {
  return (
    <ButtonCardContainer>
      <If condition={button}>
        <ButtonContainer>
          {button}
        </ButtonContainer>
      </If>
      <If condition={children}>
        <ContentContainer>
          {children}
        </ContentContainer>
      </If>
    </ButtonCardContainer>
  )
}

ButtonCard.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node,
}

export default ButtonCard
