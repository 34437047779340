
import PropTypes from 'prop-types'
import {map} from 'lodash-es'
import styled, {css} from 'styled-components'
import media from '../utils/media'


const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: ${({theme}) => theme.responsive.container.desktop};
  ${({theme}) => {
    const maxWidths = map(theme.responsive.container, (value, key) => {
      return media.down(key)(css`
        max-width: ${value};
      `)
    })
    return maxWidths
  }}
`

const ContentContainer = ({children}) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

ContentContainer.propTypes = {
  children: PropTypes.node,
}

export default ContentContainer
