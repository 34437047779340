import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/calendar.svg'


const CalendarIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

CalendarIcon.defaultProps = {
  ...defaultProps,
}

CalendarIcon.propTypes = {
  ...propTypes,
}

export default CalendarIcon
