
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'


const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 2.5rem 0;
  width: 100%;
  ${media.down('mobile')(css`
    grid-template-columns: auto;
  `)}
`

const AccordionContainer = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

AccordionContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AccordionContainer
