import {forwardRef, Children} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import omitProps from '../utils/omitProps'


const alignmentMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  justify: 'space-between',
  evenly: 'space-evenly',
}


const StyledDiv = styled('div').withConfig(omitProps(['column', 'width', 'height', 'reverse', 'wrap']))`
  /* Disable selector due to incorrect errors */
  /* stylelint-disable indentation, selector-max-empty-lines */
  ${({column, width, height}) => column
    ? css`
      display: flex;
      width: ${width === 'fill' ? '100%' : width === 'hug' ? 'auto' : width};
      height: ${height === 'fill' ? '100%' : height === 'hug' ? 'auto' : height};
    ` : css`
      display: ${width === 'fill' ? 'flex' : 'inline-flex'};
      width: ${width === 'fill' ? 'auto' : width === 'hug' ? 'auto' : width};
      height: ${height === 'fill' ? '100%' : height === 'hug' ? 'auto' : height};
    `}
  flex-direction: ${({column, reverse}) => `${column ? 'column' : 'row'}${reverse ? '-reverse' : ''}`};
  align-items: ${({crossAxis}) => alignmentMap[crossAxis]};
  justify-content: ${({mainAxis}) => alignmentMap[mainAxis]};
  max-width: ${({maxWidth}) => maxWidth};

  ${({theme, column, gap}) => {
    if ((gap && column)) {
      return css`
        margin-top: calc(-${theme.spacer} * 0.5);
        margin-bottom: calc(-${theme.spacer} * 0.5);
      `
    }
    return css`
      margin-right: calc(-${theme.spacer} * 0.5);
      margin-left: calc(-${theme.spacer} * 0.5);
    `
  }}

  ${({wrap}) => wrap && css`
    flex-wrap: wrap;
  `}

  ${({theme, responsive, column, reverse}) => responsive && media.down('mobile')(css`
    margin-top: calc(-${theme.spacer} * 0.5);
    margin-right: 0;
    margin-bottom: calc(-${theme.spacer} * 0.5);
    margin-left: 0;
    width: 100%;
    ${!column && css`
      display: flex;
      flex-direction: column${reverse ? '-reverse' : ''};
      align-items: ${({mainAxis}) => alignmentMap[mainAxis]};
      justify-content: ${({crossAxis}) => alignmentMap[crossAxis]};
    `}
  `)}

  & > div {
    ${({distribute}) => distribute && css`
      flex-basis: 100%;
      flex-shrink: 1;
    `}
    ${({responsive, distribute}) => responsive && distribute && media.down('mobile')(css`
      flex-shrink: 0;
    `)}
    ${({theme, column, gap}) => {
    /* eslint-disable indent */
      if ((column && gap)) {
        return css`
          padding-top: calc(${theme.spacer} * 0.5);
          padding-bottom: calc(${theme.spacer} * 0.5);
        `
      }

      return css`
        padding-right: calc(${theme.spacer} * 0.5);
        padding-left: calc(${theme.spacer} * 0.5);
        ${media.down('mobile')(css`
          padding-top: calc(${theme.spacer} * 0.5);
          padding-bottom: calc(${theme.spacer} * 0.5);
        `)}
      `
    }}
  }
`

const Flex = forwardRef(({
  component, column, reverse, mainAxis, crossAxis, width, height, gap, responsive, wrap, distribute, maxWidth,
  children, ...props
}, ref) => {
  return (
    <StyledDiv
        ref={ref}
        as={component}
        column={column}
        reverse={reverse}
        mainAxis={mainAxis}
        crossAxis={crossAxis}
        width={width}
        height={height}
        gap={gap}
        responsive={responsive}
        wrap={wrap}
        distribute={distribute}
        maxWidth={maxWidth}
        {...props}
    >
      {Children.map(children, (child) => (
        <div>
          {child}
        </div>
      ))}
    </StyledDiv>
  )
})
Flex.displayName = 'Flex'
Flex.defaultProps = {
  component: 'div',
  gap: true,
  mainAxis: 'start',
  crossAxis: 'stretch',
  width: 'fill',
}

Flex.propTypes = {
  component: PropTypes.elementType,
  column: PropTypes.bool,
  reverse: PropTypes.bool,
  mainAxis: PropTypes.oneOf(['start', 'center', 'end', 'justify', 'evenly']),
  crossAxis: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
  width: PropTypes.oneOfType([PropTypes.oneOf(['fill', 'hug']), PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.oneOf(['fill', 'hug']), PropTypes.string]),
  gap: PropTypes.bool,
  responsive: PropTypes.bool,
  wrap: PropTypes.bool,
  distribute: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
}

export default Flex
