import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../utils/media'
import * as typography from '../theme/typography'
import Checkbox from './form/Checkbox'
import Button from './Button'


const StyledBar = styled.div`
  position: fixed;
  bottom: 0;
  transform: ${({isVisible}) => isVisible ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.3s ease-in;
  z-index: 9999;
  background-color: ${({theme}) => theme.overlays.backgroundNoBlurCookieBar};
  padding: 2rem 6.5rem;
  width: 100%;
  max-width: 100vw;
  color: ${({theme}) => theme.colors.text2};

  @supports (backdrop-filter: ${(({theme}) => theme.backdropBlur)}) {
    background-color: ${({theme}) => theme.overlays.background};
    backdrop-filter: ${(({theme}) => theme.backdropBlur)};
  }

  ${media.down('mobile')(css`
    flex-flow: column;
    margin-right: 0;
    padding: 1.25rem 2.5rem;
    text-align: center;
  `)}
`

const StyledTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  ${typography.h6};
  font-weight: bold;
`

const StyledText = styled.div`
  display: flex;
  justify-content: center;
  ${typography.body};

  p {
    padding: ${({theme}) => theme.spacer} 0 0;
    max-width: 60rem;
    ${media.down('mobile')(css`
      width: 100%;
      padding: calc(${({theme}) => theme.spacer} * 2.5) 0 ${({theme}) => theme.spacer};
    `)}

    a {
      cursor: auto;
      text-decoration: none;
      color: ${({theme}) => theme.colors.text2};
    }
  }
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  ${media.up('mobile')(css`
    label {
      &:not(:last-child) {
        margin-right: calc(${({theme}) => theme.spacer} * 6);
      }
    }
  `)}
  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: start;
    width: max-content;
    margin: 0 auto;
  `)}
`

const StyledCheckbox = styled(Checkbox)`
  ${media.down('mobile')(css`
    margin-bottom: 1.5rem;
  `)}
`

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(${({theme}) => theme.spacer} * 2.5) 0 0;
  ${media.down('mobile')(css`
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: min-content;
      margin: 0 0 calc(${({theme}) => theme.spacer} * 1.5) 0;

      &:not(:last-child) {
        margin-right: calc(${({theme}) => theme.spacer} * 1.5);
      }
    }
  `)}

  button {
    &:not(:last-child) {
      margin-right: calc(${({theme}) => theme.spacer} * 3.5);
    }
  }
`

const StyledButton = styled(Button)`
  span {
    ${typography.h6}
  }
`

const CookieBar = ({visible, selectedCookies, visibleOptions, onAllow, onReject, onAllowSelected, onOpenOptions,
  onSelectedChange, ...props}) => {
  const cookieContent = `<p>Na tomto webe používame cookies, aby sme návštevníkom poskytli čo najväčšie pohodlie
  pri používaní tejto stránky. Viac informácií o tom aké súbory cookies zbierame a na čo ich používame nájdete
  <a href="https://docs.google.com/document/d/1bsn2zESZfGPCUnekI26kTOkDMjc4ZThcsMg9G0RdtJY/edit" target="_blank">
  tu</a>.</p>`

  return (
    <StyledBar isVisible={visible} {...props}>
      <StyledTitle variant="h2" type="h6">Naša cookies politika</StyledTitle>
      <div>
        <StyledText
            component="div"
            color="textInverted"
            dangerouslySetInnerHTML={{__html: cookieContent}}
        />
        <If condition={visibleOptions}>
          <StyledCheckboxContainer>
            <StyledCheckbox label="Technické súbory cookie" checked />
            <StyledCheckbox
                name="analyticalCookies"
                label="Analytické súbory cookie"
                value={selectedCookies?.analyticalCookies}
                checked={selectedCookies?.analyticalCookies}
                onChange={onSelectedChange}
            />
            <StyledCheckbox
                name="marketingCookies"
                label="Marketingové súbory cookie"
                value={selectedCookies?.marketingCookies}
                checked={selectedCookies?.marketingCookies}
                onChange={onSelectedChange}
            />
          </StyledCheckboxContainer>
        </If>
        <StyledButtonsContainer>
          <StyledButton inverted onClick={onReject} color="secondary">ODMIETNUŤ</StyledButton>
          <If condition={!visibleOptions}>
            <StyledButton inverted onClick={onOpenOptions} color="secondary">MOŽNOSTI</StyledButton>
          </If>
          <If condition={visibleOptions}>
            <StyledButton inverted onClick={onAllowSelected} color="secondary">POVOLIŤ VYBRANÉ</StyledButton>
          </If>
          <StyledButton onClick={onAllow}>POVOLIŤ VŠETKO</StyledButton>
        </StyledButtonsContainer>
      </div>
    </StyledBar>
  )
}

CookieBar.propTypes = {
  visible: PropTypes.bool,
  selectedCookies: PropTypes.object,
  visibleOptions: PropTypes.bool,
  onAllow: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onAllowSelected: PropTypes.func.isRequired,
  onOpenOptions: PropTypes.func.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
}

export default CookieBar
