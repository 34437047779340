import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/ai.svg'


const AIIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

AIIcon.defaultProps = {
  ...defaultProps,
}

AIIcon.propTypes = {
  ...propTypes,
}

export default AIIcon
