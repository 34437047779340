import {createGlobalStyle, css} from 'styled-components'
import {normalize} from 'styled-normalize'
import media from '../utils/media'
import typography from './typography'


export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }

  /* stylelint-disable */
  ${normalize}
  /* stylelint-enable */

  :root {
    scroll-behavior: smooth;
    line-height: ${({theme}) => theme.fonts.lineHeight.sm};
    font-family: ${({theme}) => theme.fonts.family.primary}, sans-serif;
    font-size: ${({theme}) => theme.responsive.zoom.fontSizeBase}px;

    /* Devices too small */
    ${({theme}) => media.down(theme.responsive.zoom.minimalViewport)(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile / (theme.responsive.zoom.minimalViewport) * 100}vw;
    `)}

    /* Mobile */
    ${({theme}) => media.between(theme.responsive.zoom.minimalViewport, 'mobile')(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile}px;
    `)}

    /* Desktop and larger */
    ${({theme}) => media.up('mobile')(css`
      font-size: ${(theme.responsive.zoom.fontSizeBase / theme.responsive.zoom.designedViewport) * 100}vw;
    `)}

    ${({theme}) => media.up(theme.responsive.zoom.maximalViewport)(css`
      @media only screen and (max-resolution: 96dpi) {
        font-size: 12pt;
      }
      @media only screen and (-webkit-max-device-pixel-ratio: 1.33) {
        font-size: 12pt;
      }
    `)}
  }

  body {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.text};
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.text};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p:not(:first-child) {
    margin-block-start: ${({theme}) => theme.spacer};
    margin-block-end: 0;
  }

  details > summary {
    list-style: none;
  }

  details > summary::marker, /* Latest Chrome, Edge, Firefox */
  details > summary::-webkit-details-marker /* Safari */ {
    display: none;
  }

  /* stylelint-disable */
  ${typography}
  /* stylelint-enable */
`
