import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import * as typography from '../theme/typography'
import ContentContainer from './ContentContainer'


const StyledFooter = styled.footer`
  background-color: ${({theme}) => theme.colors.background4};
  padding: 2.75rem 0;
  ${media.down('mobile')(css`
    flex-direction: column;
  `)}

  a {
    display: inline-block;
    color: ${({theme}) => theme.colors.text2};
  }
`

const StyledFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  ${media.down('mobile')(css`
    grid-template-columns: auto;
    gap: 1rem;
    justify-items: center;
  `)}
`

const StyledEmail = styled.div`
  ${typography.h5}
  padding-bottom: ${({theme}) => theme.spacer};
`

const StyledSocialContainer = styled.div`
  flex: 1;
  text-align: center;
`

const StyledCookiesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0;
  list-style: none;
  ${media.down('mobile')(css`
    align-items: start;
  `)}
`

const StyledIcon = styled.a`
  padding: 0 ${({theme}) => theme.spacer};
`

const StyledFooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  ${media.down('mobile')(css`
    align-items: center;
  `)}

  img {
    padding-bottom: 1rem;
    width: 10rem;
    height: auto;
  }
`

const Footer = ({children, ...props}) => {
  return (
    <StyledFooter {...props}>
      <ContentContainer>
        <StyledFooterWrapper>
          {children}
        </StyledFooterWrapper>
      </ContentContainer>
    </StyledFooter>
  )
}

Footer.Cookies = StyledCookiesContainer
Footer.Socials = StyledSocialContainer
Footer.Social = StyledIcon
Footer.Email = StyledEmail
Footer.Logo = StyledFooterLogo

Footer.propTypes = {
  cookies: PropTypes.array,
  children: PropTypes.node,
}

export default Footer
