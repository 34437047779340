import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import * as typography from '../theme/typography'
import media from '../utils/media'
import ContentContainer from './ContentContainer'


const HeroContainer = styled.header`
  display: grid;
  position: relative;
  place-items: center;
  background-image: url(${({bgImage}) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 0;
  min-height: 100vh;
  ${({theme}) => theme.heroTexture && css`
    ::before {
      position: absolute;
      top: 0;
      background-image: url(${theme.heroTexture});
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 1.5rem;
      content: '';
      transform: rotate(180deg);
    }

    ::after {
      position: absolute;
      bottom: 0;
      background-image: url(${theme.heroTexture});
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 1.5rem;
      content: '';
    }
  `}
`

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  ${media.down('mobile')(css`
    grid-template-columns: 100%;
    justify-items: center;
  `)}

  img {
    max-width: 100%;
  }
`

const StyledDescription = styled.div`
  span {
    ${typography.h2}
    display: block;
    color: transparent;
    -webkit-text-stroke: 1px ${({theme}) => theme.colors.text2};
    /* stylelint-disable-next-line property-no-unknown */
    text-stroke: 1px ${({theme}) => theme.colors.text2};
    ${media.down('mobile')(css`
      display: none;
    `)}

    b {
      display: inline;
      /* stylelint-disable-next-line property-no-unknown */
      -webkit-text-stroke: 0;
      /* stylelint-disable-next-line property-no-unknown */
      text-stroke: 0;
      color: ${({theme}) => theme.colors.text2};
    }
  }
`

const StyledCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-items: center;
  ${media.down('mobile')(css`
    grid-template-columns: auto;
  `)}
`

const Hero = ({brand, bgImage, description, children}) => {
  return (
    <HeroContainer bgImage={bgImage}>
      <ContentContainer>
        <StyledWrapper>
          {brand}
          <StyledDescription>
            {description}
          </StyledDescription>
        </StyledWrapper>
        <StyledCardsWrapper>
          {children}
        </StyledCardsWrapper>
      </ContentContainer>
    </HeroContainer>
  )
}

Hero.propTypes = {
  brand: PropTypes.node,
  bgImage: PropTypes.string,
  description: PropTypes.object,
  children: PropTypes.node,
}

export default Hero
