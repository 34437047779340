import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'


const StyledAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in, max-height 0.2s ease-in;
  opacity: 0;
  border-radius: ${({theme}) => theme.border.radius.sm};
  background-color: ${({theme}) => theme.colors.negative};
  padding: 0.75rem;
  width: 100%;
  max-height: 0;
  color: ${({theme}) => theme.colors.text2};
  ${({open}) => open && css`
    transform: scale(1);
    max-height: 3rem;
    opacity: 1;
  `}
`

const Alert = ({open, action, children}) => {
  return (
    <StyledAlert open={open}>
      <div>
        {children}
      </div>
      <div>
        {action}
      </div>
    </StyledAlert>
  )
}

Alert.propTypes = {
  open: PropTypes.bool,
  action: PropTypes.node,
  children: PropTypes.node,
}

export default Alert
