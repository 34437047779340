import {ThemeProvider, LocalFonts, fonts} from 'uikit'
import AnalyticsProvider from '../hooks/useConsent'


export default (theme) => ({
  wrapPageElement: ({element, props: {pageContext}}) => {
    return (
      <AnalyticsProvider
          gaKey={pageContext?.gaKey}
          metaPixelId={pageContext?.metaPixelId}
      >
        <ThemeProvider theme={theme}>
          <LocalFonts fonts={fonts.webFonts} />
          {element}
        </ThemeProvider>
      </AnalyticsProvider>
    )
  },
})
