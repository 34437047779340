import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/chevron-down.svg'


const ChevronIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

ChevronIcon.defaultProps = {
  ...defaultProps,
}

ChevronIcon.propTypes = {
  ...propTypes,
}

export default ChevronIcon
