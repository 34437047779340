import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import styled from 'styled-components'
import {CheckboxIcon} from '../../icons'
import * as typography from '../../theme/typography'
import Text from '../Text'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
`

const StyledCheckbox = styled.span`
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 1px solid ${({theme, inverted}) => inverted ? theme.colors.text : theme.colors.background2};
  border-radius: ${({theme}) => theme.border.radius.xs};
  width: 1.75rem;
  height: 1.75rem;

  svg {
    opacity: 0;
  }
`

const StyledInput = styled.input`
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked + ${StyledCheckbox} {
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      opacity: 1;
    }
  }
`

const StyledSpan = styled.span`
  padding-left: 1.5rem;
  text-align: left;
  ${typography.body};
  color: ${({theme, inverted}) => inverted ? theme.colors.text : theme.colors.text2};
`

const StyledText = styled(Text)`
  padding-left: 0.25rem;
`

const Checkbox = forwardRef(({name, value, label, checked, onChange, inverted, required, ...props}, ref) => {
  return (
    <StyledLabel checked={checked}>
      <StyledInput
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          required={required}
          ref={ref}
          {...props}
      />
      <StyledCheckbox inverted={inverted}>
        <CheckboxIcon color="text2" />
      </StyledCheckbox>
      <If condition={label}>
        <StyledSpan inverted={inverted}>{label}</StyledSpan>
        <If condition={required}>
          <StyledText component="span" color="negative">*&nbsp;</StyledText>
        </If>
      </If>
    </StyledLabel>
  )
})

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  inverted: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

export default Checkbox
