import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledIframe = styled.iframe`
  display: block;
  height: 100vh;
`

const Iframe = ({iframeLink, ...props}) => {
  if (!iframeLink) return null
  return (
    <StyledIframe
        frameBorder="0"
        height="100%"
        width="100%"
        src={iframeLink}
        {...props}
    />
  )
}

Iframe.propTypes = {
  iframeLink: PropTypes.string,
}

export default Iframe
