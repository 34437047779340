import Icon, {propTypes, defaultProps} from '../components/Icon'
import {ReactComponent as Svg} from '../assets/icons/twitter.svg'


const TwitterIcon = (props) => {
  return (
    <Icon icon={Svg} {...props} />
  )
}

TwitterIcon.defaultProps = {
  ...defaultProps,
}

TwitterIcon.propTypes = {
  ...propTypes,
}

export default TwitterIcon
